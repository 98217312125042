.submit-ticket {
  // height: max-content;
  &-strip {
    padding: 1.3rem;
    background-color: #e4eaef;
    border-radius: 5px;
  }
  &-container {
    background-color: white;
    padding: 0.7rem 3rem 1.5rem;
  }
  &-btns {
    margin-left: 11rem;
    margin-top: 1rem;
    & * {
      padding: 5px 20px;
    }
  }
  &-select {
    margin-left: 11.1rem;
    color: var(--color-primary);
    label {
      cursor: pointer;
    }
  }
  &-files {
    width: 48.2%;
    flex-flow: row wrap;
    margin-left: 11.1rem;
  }

  &-file {
    border: 1.5px solid #bbd8fb;
    background-color: #f3f7fd;
    border-radius: 20px;
    margin-bottom: 0.8rem;
    padding: 1px 10px;

    div {
      height: 80%;
      border-radius: 50%;
      background-color: #dde4ec;

      img {
        width: 18px;
        height: 6px;
      }
    }
  }

  &-file-input {
    label.custom-file-label {
      border: none;
      color: var(--color-primary);
      display: flex;
      margin-left: 10.4rem;
    }

    input:hover {
      cursor: pointer;
    }

    .custom-file-label::after {
      content: none;
    }

    input.custom-file-input {
      width: 6.85rem;
      margin-left: 10.4rem;
    }

    .custom-file {
      max-width: 50.5%;
    }
  }
}

h4 {
  color: var(--color-text);
}

#file-upload-button:hover {
  cursor: pointer;
}

.select-user-name {
  margin-left: -1rem;
  z-index: 2;
  :focus {
    outline: none !important;
  }
}
