.group-users-section {
  padding: 1.25rem 0.8rem;

  &-header {
    font-size: 1.5rem;
  }

  &-add {
    z-index: 10;
    margin-bottom: 0.2rem;
    width: max-content;
  }
}
.group-user-header {
  color: #2c303a;
  &:hover {
    color: #2c303a;
    text-decoration: solid;
  }
}
.status {
  border-radius: 20px;
  padding: 5px 25px;
  width: 5rem;
  &-active {
    background-color: #00a00015;
    color: var(--color-green-text);
  }
  &-invited {
    background-color: #e3f5ff;
    color: #13a4fd;
  }
  &-disabled {
    background-color: #efefef;
    color: #909090;
  }
}
