.select-company {
  &:focus {
    outline: (--var-primary) auto 5px;
    border: none;
  }
}

.error-message {
  font-size: 0.8rem;
}

.color-gray {
  color: var(--color-grey-text);
}
