.form-layout {
  border: 1px solid #e8eaee;
  border-radius: 2px;

  &-header {
    background-color: #e4eaef;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.4rem 1rem;
    color: #7d8f95;
    min-height: 2rem;
  }

  &-body {
    background-color: white;
  }
}
