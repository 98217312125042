.storylines-section {
  padding: 1rem 1rem;

  &-outer {
    margin-bottom: -2rem;
  }
  &-search {
    width: 18%;
  }

  &-add {
    z-index: 10;
  }

  .nav-tabs .nav-link {
    font-size: 1rem;
    font-weight: 500;
    color: grey;
    border: 1px solid #dee2e6;
    background-color: #f3f6fa;
    &:focus,
    &:hover {
      outline: none !important;
      box-shadow: none;
    }
    svg {
      .a {
        fill: #85989e;
      }
    }
    &.active {
      svg {
        .a {
          fill: var(--color-primary);
        }
      }
      color: var(--color-primary) !important;
      background-color: var(--color-white);
      border: none;
    }
  }

  .status {
    border-radius: 20px;
    padding: 5px 25px;
    width: 5rem;
    &-active {
      background-color: #00a00015;
      color: var(--color-green-text);
    }
    &-invited {
      background-color: #e3f5ff;
      color: #13a4fd;
    }
    &-disabled {
      background-color: #efefef;
      color: #909090;
    }
  }
}
