.auth-layout {
  //min-height: 100%;
  //width: 100vw;
  background-color: var(--color-white);
  overflow: auto;
}

.left-section {
  width: 50%;
  //flex-grow: 1;
  &-signin {
    height: 100%;
  }
}

.right-section {
  width: 50%;
  //height: 100vh;
  &-image {
    width: 100%;
    object-fit: cover;
    @media all and (max-height: 600px) {
      height: 100%;
    }
    @media all and (min-height: 601px) {
      height: 100vh ;
    }
  }
}
