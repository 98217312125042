.topnav-container {
  margin-top: 3%;
  width: 98%;
  background-color: #e4eaef;
  a {
    text-decoration: none;
    color: inherit;
    height: 100%;
  }

  div {
    &:last-child {
      border-right: none !important;
    }
  }
  .item-container {
    flex: 1 1 0px;
    border-right: 1px solid #c0bfbf61;
    height: 2.3rem;

    .topbar-icon {
      path {
        fill: var(--color-primary);
        stroke: var(--color-primary);
      }
    }
  }
  .itemnav {
    &-link {
      img {
        height: 1rem;
        width: 1rem;
      }
    }

    &-active {
      color: var(--color-primary);
      border-bottom: 3px solid var(--color-primary);
      background-color: #f1e2e0;
    }
  }
}

.topnav-icon {
  height: 15px;
  &-active {
    & path {
      fill: var(--color-primary);
    }
  }
  &-inactive {
    & path {
      fill: #3d424b;
    }
  }
}