.login-modal {
  .modal-dialog-centered {
    justify-content: center;
  }

  .modal-content {
    width: auto;
    min-width: 328px;
  }

  .modal-title {
    padding-right: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .pure-modal-footer {
    padding: 16px;
  }

  &-img {
    margin-right: 14px;
    border-radius: 50%;
    border: 4px solid rgba(254, 80, 0, 0.12);
    strong {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background: var(--color-primary);
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: large;
    }
  }
}