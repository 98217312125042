@import "src/assets/styles/helpers";

.users-section {
  padding: 1rem 1rem;

  &-outer {
    margin-bottom: -2rem;
  }
  &-search {
    width: 22%;
  }

  &-add {
    z-index: 10;
  }

  &-token {
    color: var(--primary);
    &-warning {
      font-size: 16px;
    }
  }

  &-token-modal {
    .modal-content {
      min-height: 200px;
    }
    .modal-body {
      display: flex;
      flex-flow: column wrap;
    }
  }


  @include style-tabs;

  .status {
    border-radius: 20px;
    padding: 5px 25px;
    width: 5rem;
    &-active {
      background-color: #00a00015;
      color: var(--color-green-text);
    }
    &-invited {
      background-color: #e3f5ff;
      color: #13a4fd;
    }
    &-disabled {
      background-color: #efefef;
      color: #909090;
    }
  }
}
