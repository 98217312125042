.tabs-layout {
  padding: 1.25rem 0.8rem;
  &-header {
    display: inline-block;
    font-size: 1.5rem;
  }
  .nav-tabs .nav-link {
    font-size: 1rem;
    font-weight: 500;
    color: grey;
    &:focus,
    &:hover {
      outline: none !important;
      box-shadow: none;
      border-color: transparent;
    }
    svg {
      .g {
        fill: #85989e;
      }
    }
    &.active {
      svg {
        .g {
          fill: var(--color-primary);
        }
      }
      color: var(--color-primary) !important;
      border: none;
      background-color: transparent;
      border-bottom: 3px solid var(--color-primary);
    }
  }
}

.icon-enabled {
  & path {
    fill: var(--color-primary);
  }
}

.login-infographic-tooltip {
  .arrow {
    transform: none !important;
    right: 50% !important;
    left: 50% !important;
  }
}
