.presentations-section {
  padding: 1.25rem 0.8rem;

  &-header {
    font-size: 1.5rem;
  }
  &-search {
    width: 15.5rem;
  }

  &-add {
    z-index: 10;
    margin-bottom: 0.2rem;
    width: max-content;
  }
}
.system_group {
  font-size: 13px;
  font-style: oblique;
}

.breadcrumb {
  &-label {
    font-size: 1rem;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
