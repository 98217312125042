.vouchers-section {
  // font-size: 0.8rem;
  padding: 2rem 0.8rem;
  &-search {
    width: 20.1rem;
  }

  &-outer {
    width: 36%;
  }
  &-header {
    font-size: 1.5rem;
  }

  &-add {
    width: max-content;
    z-index: 10;
    margin-bottom: 1px;
  }
}

.voucher-name {
  color: var(--color-primary);
  cursor: pointer;
}

.voucher-code {
  font-size: small;
  display: inline-block;
  width: 92%;
}

.tag-text {
  color: var(--color-primary);
}

.voucher-container {
  display: flex;
  align-items: center;
  padding: 5px 0 0 30px
}