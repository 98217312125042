.bs-popover {
  &-bottom {
    padding: 5px 10px;
    font-size: small;
    margin-left: -5px;
  }
  &-top {
    padding: 5px 10px;
    font-size: small;
    margin-left: -5px;
  }
  &-left {
    padding: 5px 10px;
    font-size: small;
    margin-left: -5px;
  }
}
