.login {
  &-header {
    font-size: 1.3rem;
    letter-spacing: 0.42px;
    color: var(--color-secondary-text);
    opacity: 1;
    font-weight: 500;
  }

  &-logo {
      img {
        width: 90%;
        height: 80%;
      }
  }

  &-section {
    padding: 30px 30px 10px;
    width: 50%;
    background-color: var(--color-login-background);
    border-radius: 0.2rem;
    & input:focus,
    & input:active {
      outline: none !important;
      box-shadow: none;
    }
    &-text {
      font-size: 0.875rem;
      color: var(--color-secondary-text);
    }
    &-header {
      font-size: 1.3rem;
      letter-spacing: 0.42px;
      color: var(--color-primary);
      opacity: 1;
      font-weight: bolder;
    }
    &-forgot-Password {
      font-size: 0.6rem;
      color: var(--color-secondary-text);
    }
  }
}
