.outer-layout {
  height: inherit;
  overflow: hidden;
}

.panel {
  background-color: var(--color-background);
  display: flex;
  justify-content: flex-start;
}

.side-panel {
  flex: 1 1;
  &_fixed {
    position: fixed;
    top: 3.8rem;
  }
}

.main-panel {
  flex: 5.5 1;
}

.y-scroll {
  overflow-y: auto;
}
