/* Animation */
@import "src/assets/styles/main.scss";

.scale-out {
  animation: scaleOut 500ms ease-in-out 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes scaleOut {
  0% {
    transform: scaleX(-1);
  }
  100% {
    transform: scaleX(1);
  }
}

.scale-in {
  animation: scaleIn 500ms ease-in-out 0s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}

@keyframes scaleIn {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(-1);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    left: -100%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

.slide-in {
  animation: slideIn 0.5s;
}

@keyframes slideBottom {
  0% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}

.slide-bottom {
  animation: slideBottom 0.5s;
}

.colorless-link {
  color: inherit;
}

.link-decoration-none {
  text-decoration: none;
}

.color-primary {
  color: var(--color-primary);
}

.visibility-hidden {
  visibility: hidden;
}
