@import "src/assets/styles/helpers";

.creds {
  &-field {
    margin: 1rem 2rem;
  }
  &-btns {
    margin-bottom: 2px;
    &_btn {
      padding: 0.2rem 1.6rem;
      margin-right: 1.2rem;
      &:last-child {
        border: none;
        background-color: var(--color-primary);
      }
    }
  }
  &-sectionError {
    position: absolute;
    top: 2.8rem;
    left: 1.6rem;
  }
  &-error {
    margin-left: 2rem;
  }
  &-nameError {
    position: relative;
    left: 5.58rem;
    top: 0.5rem;
  }
  &-section {
    @include section-shadow;
  }
}
