.pure-modal {
  &-header {
    .modal-title {
      font-size: 1.3rem;
    }
    padding: 0rem 1rem;
    height: 3rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    background-color: var(--color-modal-header);
    color: var(--color-white);
    cursor: pointer;
  }

  &-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
    &-btn {
      width: 23%;
      min-width: max-content;
    }
  }
}
