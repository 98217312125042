.storypage {
  &-text {
    width: 50px;
  }
  &-input {
    width: 15rem;
    height: 33px;
    padding: 0 5px;
  }
}
.toc-wrapper {
  // width: 25%;
  img {
    cursor: pointer;
  }
  // min-width: 15%;
}

.editor-wrapper {
  // width: 75%;
}
