.redeem-voucher {
  &-forgot-pass {
    font-size: 0.6rem;
    color: var(--color-secondary-text);
    position: relative;
  }
  &-pass-err {
    bottom: 2.1rem;
  }
  &-register {
    box-shadow: 0 1px 8px 2px rgba(175, 175, 175, 0.3);
    // color: var(--color-secondary-text);
    color: #828588;
    font-weight: bold;
    font-size: large;
    span {
      color: var(--color-primary);
    }
  }
}

.voucher {
  &-register {
    &-section {
      width: 63%;
      &_terms {
        color: var(--color-secondary-text);
        span {
          color: #286fcc;
        }
      }
      //&_img {
      //  height: 100%;
      //}
    }
  }
}
