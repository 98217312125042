.storyline-section {
  padding: 1.25rem 0.8rem;

  &-header {
    font-size: 1.5rem;
  }

  &-cancel {
    border: 1px solid;
    &:hover {
      text-decoration: none;
    }
  }

  &-add {
    z-index: 10;
    margin-bottom: 0.2rem;
    width: max-content;
    &-existing {
      color: var(--color-primary);
      background: var(--color-white);
    }
    &-section {
      background: none;
      color: #adb8bc;
      border: none;
      cursor: pointer;
    }
  }

  .content-heading {
    color: #6d7e85;
    font-size: 1.3rem;
  }
}
.system_group {
  font-size: 13px;
  font-style: oblique;
}

.timestamp {
  color: #6d7e85;
}

.cancel-link {
  &:hover {
    text-decoration: none;
  }
}

.storyline-tabs .nav-link {
  width: 8rem;
  text-align: center;
  border: none;
  color: var(--color-black);
}

.storyline-tabs .nav-link.active {
  color: var(--color-primary);
  border-bottom: 3px solid var(--color-primary);
}
