$theme-colors: (
  "primary": #fe5000,
  "white": #fdfdfd,
  "gray-light": #f4f7f8,
  "light": #f4f7f8,
  "gray": #6c757d,
  "gray-dark": #222222,
  "secondary": #696b6f,
  "success": #65bc65,
  "warning": #ffc107,
  "danger": #dc3545,
  "dark": #0e7cbf,
  "gray-mid": #ced4da,
);

@import "~bootstrap/scss/bootstrap";

:root {
  --color-disabled-text: #909090;
  --color-background: #eff3f9;
  --color-text: #2c303a;
  --color-secondary-text: #5c7179;
  --color-white: #ffffff;
  --color-white-card: #00000014;
  --color-grey-text: #696b6f;
  --color-launch-button: #eff5ff;
  --color-button-label: #222222;
  --color-primary: #fe5000;
  --color-card-purple: #685fe3;
  --color-search-field-background: #ffffff00;
  --color-filter-label: #313541;
  --color-border: #e5e8f0;
  --color-light-orange: #fa7802;
  --color-black: #000000;
  --color-modal-header: #37485b;
  --color-footer-white: #0000000f;
  --color-input-field: #d6d3d3;
  --color-secondary-button: #667587;
  --color-placeholder: #444444;
  --color-fresh-red: #f42134;
  --color-dark-orange: #f47821;
  --color-info: #7e7e7e;
  --color-login-background: #f2f2f2d9;
  --color-table-header: #f8f9fc;
  --color-green-text: #00a000;
  --color-card-border: #00c435;
  --color-card-background: #ecfcef;
  --color-sidebar-font: #919191;
  --color-sidebar-icon-background-highlight: #0a0809;
  --color-light-blue-text: #13a4fd;
  --color-header-text: #4f6269;
  --color-header-icons: #2b2b2b;
}
