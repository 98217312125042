.lab-launch-modal {
  & .modal-body,
  & .pure-modal-header {
    padding: 1.5rem;
  }

  & .pure-modal-footer {
    padding-bottom: 2rem;
  }
}
.usage-tabs {
  margin-bottom: 1rem;
  border: 1px solid var(--color-border);
  & .tab-content {
    padding: 0 1rem;
  }
}

.usage-type .nav-link {
  width: 50%;
  text-align: center;
  color: var(--color-black);
  background-color: #ebeef2;
}

.usage-type .nav-link.active {
  color: var(--color-white);
  background-color: var(--color-primary);
}

.usage-type-options {
  input[type="radio"] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 4%;
  }

  label {
    margin-left: 1rem;
  }

  .check {
    display: inline-block;
    border: 1px solid var(--color-disabled-text);
    height: 25px;
    width: 25px;
    border-radius: 100%;
  }

  input[type="radio"]:checked {
    & ~ .check {
      position: absolute;
      border: 1px solid var(--color-primary);
    }
    & ~ .check div {
      height: 15px;
      width: 15px;
      background-color: var(--color-primary);
      border: 1px solid var(--color-primary);
      border-radius: 100%;
      position: relative;
      left: 4px;
      top: 4px;
    }
    & ~ label {
      margin-left: 2.54rem;
    }
  }
}
