@import "../../assets/styles/include-media";

.infographic {
  display: flex;
  flex-direction: column;
  &-chart {
    &-bar {
      width: 66%;
    }
    &-radial {
      width: 32%;
      & .recharts-wrapper {
        display: flex;
        justify-content: center;
      }
      &-text {
        position: absolute;
        left: 25%;
        top: 47%;
        width: 50%;
        display: flex;
        justify-content: center;
      }
    }
  }
  &-timeline {
    padding: 4px;
    border-radius: 7px;
    background-color: #eff3f9;
    & .list-group-item {
      background-color: #eff3f9;
      padding: 8px 15px;
      border: none;
      border-radius: 7px;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.3px;
      color: #8f9ea5;
    }
    & .active {
      background-color: var(--color-primary);
      border-radius: 7px;
      color: #efebeb;
    }
    & .list-group-item:last-child {
      border-radius: 7px;
    }
    & .list-group-item:first-child {
      border-radius: 7px;
    }
  }
}

.chart-label {
  font-size: 15px;
}

.infographic-datepicker {
  position: relative;
  bottom: 40px;
  align-self: flex-end;
  & .CalendarDay__selected_span {
    background: #f1e5df;
    color: black;
    border-color: var(--color-primary);
  }
  & .CalendarDay__hovered_span {
    background: #f1e5df;
    color: black;
    border-color: var(--color-primary);
  }
  & .CalendarDay__default {
    //border: none;
  }

  & .CalendarDay__selected_start,
  .CalendarDay__selected_end {
    background: var(--color-primary);
    color: white;
    border-color: var(--color-primary);
    //border-radius: 50%;
  }

  & .DateRangePickerInput {
    border-radius: 5px;
    //padding-right: 5px;
    & .DateRangePicker_picker {
      z-index: 11;
    }
    div:last-of-type {
      input {
        text-align: end;
      }
    }
  }
}
