.wrapper {
  width: 90%;
}

.StepProgress {
  position: relative;
  padding-left: 45px;
  list-style: none;
  &-item {
    position: relative;
    counter-increment: list;
    display: flex;

    &:not(:last-child) {
      padding-bottom: 20px;
    }

    &::before {
      display: inline-block;
      content: "";
      position: absolute;
      left: -2.1rem;
      height: 100%;
      width: 0.9rem;
      top: 0.5rem;
    }
    &:last-child::before {
      display: none;
      content: "";
      position: absolute;
      left: -2rem;
      height: 100%;
      width: 0.9rem;
    }

    &:first-child::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0.4rem;
      left: -2.5rem;
      width: 0.9rem;
      height: 0.9rem;
      border: 2px solid #ccc;
      border-radius: 50%;
      background-color: #fff;
    }
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0.4rem;
      left: -2.3rem;
      width: 0.5rem;
      height: 0.5rem;
      border: 2px solid #ccc;
      border-radius: 50%;
      background-color: #fff;
    }

    &.step-circle {
      &::before {
        border-left: 2px solid var(--color-primary);
      }
      &::after {
        font-size: 10px;
        color: #fff;
        text-align: center;
        border: 2px solid var(--color-primary);
        background-color: var(--color-primary);
      }
    }
  }
}
