.lab-card {
  padding: 1rem 1rem;
  // font-size: 0.5rem;
}

.lab-card-search {
  width: 18%;
  bottom: 0;
  align-self: flex-end;
  margin-bottom: -40px;
  &-icon {
    padding: 0px;
    background-color: transparent;
    border: none;
  }
  &-input {
    z-index: 10;
    border: none;
    padding: 0rem 0.3rem;
    background-color: transparent;
    width: 100%;
    &:focus {
      background-color: transparent;
      border: none;
      box-shadow: none;
      outline: none;
    }
  }
}

.lab-card {
  &-outer-section {
    margin-bottom: -2.6rem;
  }
  .nav-tabs .nav-link {
    font-size: 1rem;
    font-weight: 500;
    color: grey;
    &:focus,
    &:hover {
      outline: none !important;
      box-shadow: none;
      border-color: transparent;
    }
    svg {
      .g {
        fill: #85989e;
      }
    }
    &.active {
      svg {
        .g {
          fill: var(--color-primary);
        }
      }
      color: var(--color-primary) !important;
      border: none;
      background-color: transparent;
      border-bottom: 3px solid var(--color-primary);
    }
  }
}

.card-container {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(287px, 1fr));
  grid-gap: 29px;
  align-items: stretch;
  // display: flex;
  // flex-flow: row wrap;
  // justify-content: flex-start;
}

.labs-result {
  margin-top: 7.5rem;
}
