@import "src/assets/styles/helpers";

.dragging {
	border: dashed var(--color-border) 3px;
	height: calc(100% - 10px);
	//width: 16rem;
	background-color: rgba(255, 255, 255, 0.8);
	position: absolute;
	top: 5px;
	bottom: 0;
	left: 2px;
	right: 0;
	z-index: 2;

	&-container {
		position: absolute;
		top: 40%;
		right: 0;
		left: 0;
		text-align: center;
		color: grey;
		font-size: 2.2rem;
	}
}

.upload {
	$input-height: 14rem;
	$input-width: 16rem;

	.form-group, .custom-file {
		height: inherit;
	}

	.custom-file-label {
		border: none;
		left: -10px;
		height: inherit;
		padding-right: 0;
	}

	.custom-file-label::after {
		content: none;
	}

	input.custom-file-input {
		//height: $input-height + 0.5;
		//width: $input-width + 0.2;
		position: relative;
		left: 0px;
		border: 2px solid red;
		height: inherit;
		cursor: pointer;
	}

	&-image {
		&-label {
			//height: $input-height;
			//width: $input-width;
			height: inherit;
			border: 3px dashed var(--color-border);
			display: flex;
			// flex-flow: column;
			justify-content: center;
			// align-items: center;

			span {
				font-size: 1rem;
			}
			img {
				object-fit: cover;
				max-width: 100%;
                max-height: 282px;
            }
		}
		&-name {
			word-wrap: break-word;
			@include line-clamp(3);
		}
		&-text {
			font-size: 1rem;
			color: var(--color-primary);
		}

		&-selected {
			display: inline-block;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			border: 2px solid var(--color-border);
			background-color: var(--color-white);
			position: absolute;
			top: 1rem;
			right: 1em;

			img {
				margin-left: 25%;
				margin-bottom: 50%;
				width: 50%;
			}
		}
	}
}
