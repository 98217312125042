.storyline {
  &-name {
    font-weight: bold;
  }

  &-action {
    margin-left: 1rem;
    vertical-align: bottom;
  }

  &-header-editable-input {
    width: 38%;
  }
}
