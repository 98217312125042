@import "src/assets/styles/include-media";

.custom-dropdown-toggle {
  outline: none;
  color: black;
  &:hover {
    color: black;
  }
}

.selected-route {
  color: var(--color-primary);
  .menu-option-checked {
    display: unset;
  }
}

.menu-option-checked {
  display: none;
}

.dropdown {
  position: static;
  &-name {
    font-size: 0.85em;
    &-visibility {
      @include media("<1420px") {
        display: none;
      }
    }
  }
  .dropdown-menu.show {
    top: 0 !important;
    right: 0 !important;
    left: unset !important;
    bottom: unset !important;
    transform: translate(14%, 28%) !important;
    z-index: 99999;
  }
}

// .user-custom-dropdown,
// .user-custom-dropdown:hover {
//   background-color: transparent;
//   color: black;
//   display: inline-flex;
//   border: none;
//   box-shadow: none;
// }

// .header-right_profile .dropdown-toggle::after {
//   display: none;
// }

// .btn-primary:not(:disabled):not(.disabled):active:focus,
// .btn-primary:not(:disabled):not(.disabled).active:focus,
// .show > .btn-primary.dropdown-toggle:focus {
//   box-shadow: none;
// }

// .btn-primary:not(:disabled):not(.disabled):active,
// .btn-primary:not(:disabled):not(.disabled).active,
// .show > .btn-primary.dropdown-toggle {
//   background-color: transparent;
//   color: black;
//   display: flex;
//   border: none;
//   box-shadow: none;
// }
