.access-list {
  .access-heading {
    font-size: 1.2rem;
    color: var(--gray);
    font-weight: 500;
  }
  .list {
    border-left: 1px solid #fee7dd;
    // width: 33%;
    ul {
      list-style: none;
      padding-left: 0px;
    }
    ul > li {
      &:first-child {
        font-size: 1.2rem;
        padding: 0rem 0.5rem;
      }
      padding: 0.2rem 1rem;
      color: var(--gray);
      position: relative;
      .content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .align-dot {
      position: absolute;
      top: 40%;
      left: -0.2rem;
    }
    .align-dot-main {
      position: absolute;
      top: 30%;
      left: -0.28rem;
    }
    .dot {
      height: 0.3rem;
      width: 0.3rem;
      background-color: var(--color-primary);
      border-radius: 50%;
      display: inline-block;
    }
    .dot-main {
      height: 0.5rem;
      width: 0.5rem;
      background-color: var(--color-primary);
      border-radius: 50%;
      display: inline-block;
    }
  }
}
