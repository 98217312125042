.creds {
  display: flex;
  flex-flow: column;
  &-btns {
    align-self: flex-end;
    display: flex;
    &-btn {
      cursor: pointer;
      margin-left: 15px;
      color: var(--color-primary);
      span {
        margin-left: 5px;
      }
    }
  }
}
