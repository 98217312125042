.access-section {
  min-height: 20vh;
  background-color: white;

  .no-result {
    background-color: white;
    padding: 0rem;
  }

  .custom-border {
    border: 1px solid #ebedf2;
  }

  .btn-min-width {
    min-width: 10%;
  }
}
