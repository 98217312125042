.change-password-form {
  width: 50%;
  &-label {
    min-width: 40%;
  }

  &-input {
    width: 60%;
  }
}

.show-label {
  font-size: 0.8rem;
}

.cp-form-input {
  border: #ced4da 1px solid;
  border-radius: 4px;
  &-box:focus {
    border: none !important;
    box-shadow: none;
  }
}

.error-message {
  color: var(--color-fresh-red);
  font-size: 0.8rem;
}
