.error-container {
  background-color: var(--color-background);
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;

  &-header {
    width: 100%;
    align-items: center;
    height: 3.5rem;
  }

  h5 {
    color: var(--color-text);
  }

  p {
    text-align: center;
    color: var(--color-secondary-text);
    font-size: 1.1em;
  }

  button {
    width: 10rem;
  }
}
