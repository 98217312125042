.search {
  width: 100%;
  &-icon {
    z-index: 10;
    left: 1rem;

    path {
      fill: #86989f;
    }
  }

  &-cancel {
    opacity: 0;
    z-index: 10;
    right: 1rem;
    cursor: pointer;
  }

  &-box {
    &:not([value=""]) + .search-cancel {
      opacity: 1;
      z-index: 10;
      right: 1rem;
      cursor: pointer;
    }
    caret-color: var(--color-primary);
    border-radius: 0.2rem !important;
  }
}
