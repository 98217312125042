@import "src/assets/styles/include-media";
@import "src/assets/styles/helpers";
$height: calc(100vh - 475px);

@mixin banner {
  background-color: #eff3f9;
  padding: 0.5rem;
  color: #69777d;
  border-radius: 3px;
  width: 100%;
}

//.tabs-console {
//  @media (min-height: 1200px) {
//    //height: calc(100% - 38px);
//  }
//  @media (min-height: 950px) and (max-height: 1199px) {
//    //height: calc(100% - 65px);
//  }
//  @media (max-height: 949px) {
//    //height: calc(100% - 80px);
//  }
//}
//
//.tabs-storyline {
//  //height: calc(100% - 79px);
//  //.tab-content {
//  //  height: 92%;
//  //}
//}

.tab {
  &-portal-index {
    .tab-content {
      @include media(">273px") {
        height: calc(100vh - 120px);
      }
      @include media("<=273px") {
        height: calc(100vh - 158px);
      }
    }
  }
  &-portal-story {
    .tab-content {
      height: calc(100vh - 165px);
    }
  }
  &-console {
    &-index {
      .tab-content {
        @include media(">1145px") {
          height: calc(100vh - 200px);
        }
        @include media("<=1145px") {
          height: calc(100vh - 237px);
        }
      }
    }
    &-story {
      .tab-content {
        @include media(">1145px") {
          height: calc(100vh - 250px);
        }
        @include media("<=1145px") {
          height: calc(100vh - 300px);
        }
      }
    }
  }
  &-guide {
    &-index {
      .tab-content {
        @include media(">=1036px") {
          height: 30rem
        }
        @include media("<1036px") {
          height: 27rem;
        }
      }
    }
    &-story {
      .tab-content {
        @include media(">=1356px") {
          height: 27rem;
        }
        @include media("<1356px", ">=1036px") {
          height: 26rem;
        }
        @include media("<1036px") {
          height: 23rem;
        }
      }
    }
  }
}

.view {
  // overflow: scroll;
  background-color: white;
  border: 0.5rem solid #37485b;
  border-radius: 5px;
  box-sizing: border-box;

  &-breadcrumb {
    &-text {
      color: var(--color-primary);
    }
  }

  &-header {
    height: 3rem;
    background-color: #37485b;
    padding-right: 10px;
    padding-top: 10px;
  }

  &-tabs {
    display: flex;
    &-container {
      .tab-content {
        .tab-pane {
          height: 100%;
        }
      }
    }
    a {
      flex-grow: 1;
      text-align: center;
      border: 1px solid transparent;
    }
    .nav-item.nav-link {
      &.active {
        color: var(--color-primary);
        border-bottom: 2px solid var(--color-primary);
      }
      color: #2e2525;
      border: none;
      border-bottom: 1px solid #dee2e6;
      border-top: 1px solid #dee2e6;
      border-right: 1px solid #dee2e6;
      border-radius: 0;
    }
    &-content {
      height: 100%;
      // @media (min-width: 1116px) {
      //   height: calc(100vh - 250px);
      // }
      // @media (max-width: 1115px) {
      //   height: calc(100vh - 280px);
      // }
      display: flex;
      flex-flow: column;
      //justify-content: space-between;
      &-main {
        overflow: auto;
        overflow-x: hidden;
        flex: 1;
      }
    }
  }

  .line-break {
    margin: 0;
  }

  .tab-index {
    word-break: break-word;
    img {
      max-width: 100%;
    }
  }

  .intro-preview {
    img {
      max-width: 100%;
      height: auto;
      cursor: pointer;
    }
  }

  .story-preview {
    // max-height: 22rem;
    // text-overflow: scroll;
    // overflow-x: hidden;
    word-break: break-word;
    img {
      max-width: 100%;
      height: auto;
      cursor: pointer;
      //max-width: 100%;
      //max-height: 100%;
      //object-fit: scale-down;
      //&:active {
      //  z-index: 100000;
      //  position: absolute;
      //  top: 10%;
      //  left: 10%;
      //  width: max-content;
      //  height: fit-content;
      //}
    }
  }

  .intro-banner {
    @include banner();
    font-size: 1.2rem;
  }
}

.preview-section {
  &-minimise-icon {
    fill: #c4c4c4;
    height: 20px;
  }
  &-modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  &-dropdown {
    background: #eff3f9;
    color: black;
    width: -webkit-fill-available;
    text-align: left;
    border-radius: 0;
    border: none;
    cursor: pointer;

    &-body {
      background: var(--color-white);
    }
  }

  &-number {
    background: white;
    text-align: center;
    border-radius: 100%;
    flex: 0 0 auto;
    display: flex;
    width: 1.3rem;
    height: 1.3rem;
    flex-direction: column;
  }

  &-label {
    font-size: 0.8rem;
    color: #69777d;
    &-icon {
      border: 1px solid var(--color-primary);
      padding: 4px;
      background: var(--color-white);
    }

  }
  &-image {
    max-height: 80vh;
  }

  &-image-viewer {
    .modal-content {
      min-width: 90vw;
      max-width: 90vw;
      max-height: 95vh;
      position: absolute;
      top: 0;
      left: 5vw;
    }
    .modal-dialog {
      position: absolute;
    }
  }
}

.story-title {
  display: flex;
  align-items: center;

  svg {
    min-width: 10px;
  }

  span {
    display: inline-block;
  }
  span:first-child {
    width: 40px;
  }

  span:last-child {
    flex: 1 1 auto;
    min-width: 40px;
    text-align: left;
  }

  .story-middle-span {
    flex: 1 1 auto;
    min-width: 80px;
    max-width: 145px;
    text-align: center;
  }

  .story-arrow-icon {
    margin-left: 4px;
  }
}

.story {
  &-header {
    @include banner();

    & img {
      padding: 1px 2px;
      height: 27px;
    }

    b {
      flex: 1 1;
      padding: 0 10px;
      @include line-clamp(2)
    }
  }
  &-tabs {
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
  }
  &-content {
    height: calc(100vh - 270px);
  }
}

.overflow-header {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.accordian-story-option {
  &:hover {
    text-decoration: underline;
  }
}

.no-creds {
  // height: 30rem;
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.highlight {
  color: var(--color-light-blue-text);
  &-content {
    background-color: yellow;
  }
}

.accordion-toggle {
  height: fit-content;
}

.search-storyline {
  overflow-y: auto;
  height: 98%;
}

