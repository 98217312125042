.page-story .page-item.active .page-link {
  // padding: 0.05rem 0.5rem;
  color: var(--color-primary);
  background-color: var(--color-white);
}

.page-story .page-items {
  .page-link {
    box-shadow: none;
  }
  ul {
    display: flex;
    flex-flow: row wrap;
  }
}

.page-item-middle .page-link {
  border: 1px solid var(--color-primary);
}

.page-item-right .page-link {
  border-left: 1px solid var(--color-primary);
}

.page-item-left .page-link {
  border-right: 1px solid var(--color-primary);
}

.disabled-page-button {
  .page-link {
    cursor: auto;
    color: #848586;
  }
}