.groups-section {
  padding: 1.25rem 0.8rem;

  &-header {
    font-size: 1.5rem;
  }

  &-add {
    z-index: 10;
    margin-bottom: 0.2rem;
    width: max-content;
  }
}
.system_group {
  font-size: 13px;
  font-style: oblique;
}
