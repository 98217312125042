.sidebar {
  height: 100vh;
  overflow-y: auto;
  width: 15rem;
  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-sidebar-font);
    outline: 1px solid white;
    border-radius: 10px;
  }

  &-icon {
    min-height: 100%;
    height: max-content;
    width: 100%;
    position: relative;
    background-color: #222222;
    z-index: 11;
  }

  &-dropdown {
    display: flex;
    &-name {
      margin-left: 0.5em;
    }
    &-toggle {
      position: relative;
      left: 5.5rem;
    }
  }

  &-navigator {
    display: flex;
    flex-direction: column;
    justify-content: start;
    color: var(--color-sidebar-font);
  }

  &-item {
    height: 2.5rem;
    display: flex;
    flex-flow: row wrap;
    // padding: 0.8rem 8.5rem 0.8rem 1.2rem;
    align-items: center;
    margin-bottom: 2rem;
    cursor: pointer;
    &:last-child {
      margin-bottom: 5rem;
    }
    &-icon {
      filter: invert(0.5);
      align-self: center;
    }
    &-row {
      font-size: 0.92em;
      padding-left: 1.5rem;
      width: 90%;
    }

    &-name {
      letter-spacing: 0.1px;
      padding-left: 1.2em;
      outline: none;
      align-self: center;
      font-size: 1.1em;
      color: var(--color-sidebar-font);
      &:hover {
        color: var(--color-white);
        text-decoration: none;
      }
    }
    &:first-child {
      margin-top: 20px;
      a {
        padding-left: 0.8rem;
      }
    }
    &:hover {
      color: var(--color-white);
    }
  }
}

.dropdown-opts {
  display: flex;
  justify-content: flex-end;
  margin: 5px 15px 5px;
  &_img {
    background-color: var(--color-header-icons);
    border-radius: 5px;
    &:hover {
      cursor: pointer;
    }
  }
  &_text {
    font-size: small;
    width: 5.5rem;
  }
  & * {
    margin: 0 3px;
  }
  &:last-child {
    margin-bottom: 20px;
  }
}

.sidebar-item:hover,
.sidebar-active {
  border-left: 3px solid white;
  background: var(--color-sidebar-icon-background-highlight);
}

.sidebar-item:hover .sidebar-item-name,
.sidebar-active .sidebar-item-name {
  color: white;
}

.toggle {
  filter: invert(0.5);
}

.sidebar-item:hover .sidebar-item-icon,
.sidebar-active .sidebar-item-icon,
.sidebar-item:hover .toggle,
.sidebar-active .toggle {
  filter: invert(0);
}

.sidebar-item:hover .toggle {
  filter: invert(1);
}

.sidebar-active .toggle,
.sidebar-active:hover .toggle {
  filter: invert(0);
}

.item-border {
  border: 1px solid var(--color-white);
}
