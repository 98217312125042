.infographic {
  &-card {
    min-height: 0;
    width: 32%;
    & .card-text {
      p {
        font-size: large;
      }
      h1 {
        color: #24243A;
      }
    }
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
    &-total {
      text-align: left;
      font-weight: 700;
      font-size: 40px;
      color: rgb(36, 36, 58);
    }
    &-text {
      text-align: left;
      margin-bottom: 10px;
      font-size: 18px;
    }
    &-body {
      margin-bottom: 18px;
    }
  }
}