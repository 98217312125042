.login {
  &-header {
    font-size: 1.3rem;
    letter-spacing: 0.42px;
    color: var(--color-secondary-text);
    opacity: 1;
    font-weight: 500;
  }
  &-logo {
    img {
      width: 90%;
      height: 80%;
    }
  }

  &-error-message {
    height: 19px;
  }

  &-section {
    width: 55%;
    background-color: var(--color-login-background);
    border-radius: 0.2rem;
    & input:focus,
    & input:active {
      outline: none !important;
      box-shadow: none;
    }
    &-header {
      font-size: 1rem;
      letter-spacing: 0.42px;
      color: var(--color-primary);
      opacity: 1;
      font-weight: bolder;
    }
    &-forgot-password {
      font-size: 0.6rem;
    }
  }
}

.okta-signin {
  width: 43%;
}

.partner-signin {
  width: 43%;
}

.voucher-signin {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--color-secondary-text);
}

.instructions {
  color: var(--color-secondary-text);
}

.cursor-pointer :hover {
  cursor: pointer;
}

.mt-n35 {
  margin-top: -35px;
}

.mt-n10 {
  margin-top: -10px;
}

.forgot-password-link :focus,
:active,
::after {
  text-decoration: none;
  outline: none;
}

.section-form-input {
  height: 50px;
}