@import "src/assets/styles/include-media";
@import "src/assets/styles/helpers";

@mixin card-button {
  padding: 0 0.6em;
  margin: 0.4em 0.8em;
  height: 2rem;
  min-width: max-content;
  border-radius: 5px;
  text-align: center;
  letter-spacing: 0px;
}

.card {
  min-height: 19rem;
  // margin: 1.5rem 0 0 0;
  font-size: 0.8rem;
  margin: 1.5rem 0;
  background: var(--color-white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px var(--color-white-card);
  border: 0.5px solid var(--color-card-border);
  border-radius: 6px;
  opacity: 1;

  &-row {
    display: flex;
  }

  &-draft {
    background: var(--color-white);
    border: none;
  }

  &-back {
    background: var(--color-card-purple) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px var(--color-white-card);
    border-radius: 6px;
    opacity: 1;
    letter-spacing: 0.3px;
    color: var(--color-white);
    opacity: 1;
  }
  &-title {
    padding: 0 4px;
    height: 42px;
    line-height: 23px;
    font-size: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  &-description {
    @include line-clamp(3);
    color: var(--color-grey-text);
    font-size: 0.8rem;
    height: 52px;
  }
  &-text {
    color: var(--color-grey-text);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-size: 0.8rem;
    &-back {
      font-size: smaller;
      line-height: 1.5rem;
    }
  }
  &-img {
    height: 7.0625rem;
    object-fit: cover;
    border-bottom: 1px solid lightgrey;
  }
  &-lab-initials {
    color: var(--color-white);
    background-color: var(--color-card-purple);
    font-size: 2.4em;
    letter-spacing: 1.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      max-width: 80%;
    }
  }
  &-header-user-labs {
    border-bottom: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &-timestamp {
      width: 205px;
      height: 28px;
      border-radius: 3px;
      color: #86989f;
      background-color: #fff;
      display: flex;
      align-items: center;
      margin-right: 20px;

      font-size: 0.8rem;
      text-align: center;
      letter-spacing: 0.18px;
      padding: 7px 15px;
      opacity: 1;
      font-weight: bold;

      &-text {
        color: #a5a8ab;
      }
      &-time {
        color: #b8babd;
        font-weight: bold;
      }
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    }
  }
  &-box {
    height: 6.1875rem;
  }

  .icon {
    &-info {
      cursor: pointer;
      position: absolute;
      bottom: 18.5rem;
      left: 89%;
      & path {
        stroke: var(--color-grey-text);
        fill: white;
      }
    }
    &-active-status {
      position: relative;
      bottom: 1px;
    }
    &-close {
      cursor: pointer;
      float: right;
    }
    &-draft {
      visibility: hidden;
    }
    &-clock {
      margin-bottom: 3px;
    }
  }

  &-footer {
    flex: auto;
    margin-top: -1rem;
    background: none;
    border: none;
    max-height: fit-content;
    max-width: 100%;
    font-size: 14.5px;
    align-items: center;
    &-primary {
      @include card-button;
      background: var(--color-primary) 0% 0% no-repeat padding-box;
      border: 1px solid var(--color-primary);
      color: var(--color-white);
      &:hover,
      &:focus {
        outline: 5px auto var(--color-primary);
      }
    }

    &-secondary {
      @include card-button;
      background: var(--color-white) 0% 0% no-repeat padding-box;
      border: 1px solid var(--color-primary);
      opacity: 1;
      color: var(--color-primary);
      &:focus {
        outline: 5px auto var(--color-primary);
      }
      &:hover {
        outline: 5px auto var(--color-primary);
        background: var(--color-primary);
        color: var(--color-white);
      }
    }

    &-delete {
      @include card-button;
      background: #edf2fa 0% 0% no-repeat padding-box;
      border: none;
      opacity: 1;
      color: var(--color-primary);
      &:focus {
        outline: 5px auto var(--color-primary);
      }
      &:hover {
        outline: 5px auto var(--color-primary);
        background: var(--color-primary);
        color: var(--color-white);
      }
    }
  }
}

.card:last-child {
  margin-right: 0;
}

.negative-margin {
  margin-top: -1.25rem;
}
.margin-top {
  margin-top: 0.8rem;
}

.line-clamp {
  @include line-clamp(2);
}

.line-break {
  border-top: 1px solid var(--color-border);
}
