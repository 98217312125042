.error {
  & p:nth-child(2) {
    margin-top: 2.2rem;
    font-size: large;
    color: var(--color-grey-text);
  }
  & p:nth-child(3) {
    color: var(--color-secondary-text);
  }
}
