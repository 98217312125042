.error-message {
  font-size: 0.8rem;
}

.input {
  width: 100%;
  height: 45px;
  padding: 0 1rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
}

.input:focus {
  outline: none;
}

.input.has-error {
  border-color: tomato;
}

.error {
  margin: 0 0.5rem 0.5rem;
  font-size: 90%;
  color: tomato;
}

.tag-item {
  background-color: #d4d5d6;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item > .button {
  background-color: var(--color-white);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.voucher-form {
  &-row {
    align-items: center;
    img {
      height: 22px;
      margin-left: 1rem;
    }
    .form-control {
      color: #75757d;
      background-color: white;
    }
  }
  &-label {
    color: var(--color-primary);
  }
}
