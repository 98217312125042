.page-not-found-container {
  background-color: var(--color-background);
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 4rem;

  h4 {
    margin-top: 2rem;
    width: 28rem;
    text-align: center;
    color: var(--color-text);
  }

  button {
    margin-top: 3rem;
  }
}
