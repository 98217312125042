@mixin avatar($size) {
  width: $size;
  height: $size;
  background-color: rgb(6, 32, 71);
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;

  .initials {
    position: relative;
    top: $size * 0.05;
    font-size: $size * 0.47;
    line-height: $size * 0.1;
    color: #fff;
    font-family: "Courier New", monospace;
    font-weight: bold;
  }
}

@mixin section-shadow {
  box-shadow: 0px 1px 8px 1px #e8eaee;
  border: 2px solid var(--color-border);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@mixin line-clamp($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin style-tabs {
  .nav-tabs .nav-link {
    font-size: 1rem;
    font-weight: 500;
    color: grey;
    border: 1px solid #dee2e6;
    background-color: #f3f6fa;
    &:focus,
    &:hover {
      outline: none !important;
      box-shadow: none;
    }
    svg {
      .a {
        fill: #85989e;
      }
    }
    &.active {
      svg {
        .a {
          fill: var(--color-primary);
        }
      }
      color: var(--color-primary) !important;
      background-color: var(--color-white);
      border: none;
    }
  }
}
