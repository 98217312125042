.admin-layout {
  height: inherit;
  overflow: scroll;
  background-color: var(--color-background);

  .panel {
    width: 98%;
  }
}

// .main-panel {
//   overflow-y: scroll;
// }
