@import "src/assets/styles/helpers";

$input-left-margin: 0.1rem;

.border-shadow {
  @include section-shadow;
}

.section-layout {
  margin-bottom: 1.8rem;

  &-title {
    margin-left: $input-left-margin;
    color: var(--color-text);
  }

  &-header {
    background-color: #e4eaef;
    font-size: 1rem;
    padding: 0.8rem 0;
    color: #6b6b6b;
  }
  &-body {
    padding: 1.2rem 1.5rem 1.8rem;
  }
}

.section-action-icon {
  background-color: var(--color-white);
  border-radius: 6px;
}

.section-field {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-left: $input-left-margin;
  &-btn {
    cursor: pointer;
    width: 8.9rem;
    span {
      color: var(--color-primary);
      margin-left: 4px;
    }
    &_var {
      margin-left: 8px;
    }
    &_val {
      border: none;
      color: var(--color-secondary-text);
      &_border {
        border-radius: 2px;
        border: 1px solid #a9c3fd;
      }
    }
  }
  &_input {
    width: 7rem;
    display: inline-block;
  }
}

.section-field-footer {
  &_btn {
    padding: 0.4rem;
    display: flex;
    flex-flow: row-reverse;
    margin-top: 20px;
    border-top: 0.2px solid #e8eaee;
    font-size: 0.8rem;
    button {
      outline: none;
      margin-right: 2.8rem;
      border: none;
      color: var(--color-primary);
      background-color: white;
      padding: 0;
    }
  }
}
