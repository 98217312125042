.okta-unauthorized-user-container {
  background-color: var(--color-background);
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  // padding-top: 4rem;

  h4 {
    // margin-top: 2rem;
    width: 28rem;
    text-align: center;
    color: var(--color-text);
  }

  button {
    margin-top: 1rem;
  }
}
