.companies-section {
  padding: 2rem 1rem;

  &-header {
    font-size: 1.5rem;
  }
  &-search {
    width: 18rem;
  }
  &-add {
    margin-bottom: 0.5rem;
    z-index: 10;
  }

  .no-result-found {
    margin-top: 10vh;
  }
}

.manage-notifications {
  .notification-configuration {
    .form-layout-body {
      padding: 1.5rem 3rem;
    }

    .react-datepicker {
      &-wrapper {
        width: 100%;
      }

      &-time {
        &__input {
          input {
            outline: none;
            font-size: 13px;
            padding: 2px 10px;
            line-height: 16px;
            border: 1px solid #aeaeae;
            border-radius: 4px;
            box-shadow: inset 0 2px 2px #e9e9e9;
          }
        }
      }
    }
  }

  .display-location-info {
    font-size: 12px;
  }
}

