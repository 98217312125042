.labs {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  padding: 0 9.5% 1rem;
  font-size: 1rem;
  &-icon {
    filter: invert(1);
    height: 8.5px;
    margin-top: 6.5px;
  }
  &-icon-active {
    filter: invert(0);
  }
  &-link {
    font-size: 0.9rem;
    color: var(--color-sidebar-font);
    padding-left: 1rem;
    outline: none;
    &:hover,
    &-active {
      color: var(--color-white);
      outline: none;
    }
  }
  &-items {
    font-size: 0.85rem;
    display: flex;
    flex-flow: column wrap;
    color: white;
  }
}
