.toc {
  //   border-right: 1px solid #ccc8c8;
  //   border-radius: 5px;
  //   padding: 0px 15px;
  width: 80%;
  .toc-heading {
    color: #6d7e85;
  }
  .selected-item {
    color: var(--color-primary);
    // &::before {
    //   content: "";
    //   background: url("../../../../../assets/images/right-arrow-color.svg")
    //     no-repeat 0 0;
    //   background-size: 10px 10px;
    //   display: inline-block;
    //   height: 10px;
    //   width: 10px;
    // }
  }
  ul {
    list-style: none;
    padding: 0rem 0rem;
    li {
      cursor: pointer;
      font-size: 1rem;
      color: #2f2f2f;
    }
    ul {
      list-style: none;
      padding: 0rem 1rem;
      li {
        cursor: pointer;
        color: #40494dc9;
        font-size: 0.9rem;
      }
    }
  }
}
