.form-layout {
  border: 1px solid #e8eaee;

  &-header {
    background-color: #e4eaef;
    font-size: 1rem;
    padding: 0.4rem 0rem 0.4rem 0rem;
    color: #6b6b6b;
  }

  &-body {
    background-color: var(--color-white);
  }

  &-footer {
    color: var(--color-primary);
    background-color: var(--color-white);
    padding: 0.4rem 0.4rem 0.4rem 0rem;
    font-size: 0.8rem;
    border-top: 0.2px solid #e8eaee;
  }

  &-section {
    border-radius: 3px;
    box-shadow: 0px 1px 8px 1px #e8eaee;
  }
}
.section-action-icon {
  background-color: var(--color-white);
}
.section-story-add {
  width: max-content;
}

.story-heading {
  color: #6d7e85;
}

.section-editbar {
  &-grp {
    margin-bottom: 0.1rem;
  }
  &-left {
    border: 0.2px solid #e8eaee;
  }
  &-input {
    &:hover {
      cursor: text;
    }
    &:focus {
      border: 1px solid #ced4da;
      box-shadow: none;
    }
  }
}
.footer {
  color: var(--color-primary);
  background-color: var(--color-white);
}

.import-story-dropdown {
  width: 38%;
}
