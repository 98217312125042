.clock {
  width: 3.8rem;
}

.marks {
  transform: translate(20px, 20px);
  stroke-width: 0.2;
}

@for $i from 1 through 45 {
  .marks > line:nth-child(#{$i}) {
    transform: rotate($i * 8deg);
    stroke-width: 1px;
    stroke-opacity: 0.7;
  }
}

.clock svg {
  transform: rotate(-90deg);
  height: 100%;
}

.time {
  stroke-width: 1px;
  &-text {
    transform: rotate(90deg);
    font-size: 0.55rem;
  }
}

//@keyframes rotateHand {
//  100% {
//    transform: rotate(360deg);
//  }
//}
